/**
 * MOBILE-ONLY STYLES
 * Class name "mobile-view" added in src/index.tsx if user-agent is mobile
 *
 * Temporary mobile overrides for style to fit with current mobile apps.
 * These can be deleted once mobile and web are using a consistent style.
 */
.app.mobile-view .login-component {
  padding: none;
}


.app.mobile-view a:focus {
  outline-color: transparent;
  outline-style: none;
  box-shadow: 0 0 0 0.1875rem #ed2184;
}

/**
 * Login component
 */ 
.app.mobile-view .login-component {
  min-height: 175px;
}

/**
 * Overriding Ray styles
 */ 
.app.tanooki.mobile-view .ray-text-field {
  border: none;
  border-bottom: .0625rem solid #666;
  border-radius: inherit;
}

.app.tanooki.mobile-view .ray-text-field__label {
  font-family: -apple-system, BlinkMacSystemFont,
  'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
}

.app.tanooki.mobile-view .ray-text-field:focus-within {
  border-color: #e6e6e6;
}

.app.tanooki.mobile-view .ray-text-field:focus-within .ray-text-field__label {
  color: #666666;
}
